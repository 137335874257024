/* eslint-disable global-require */
import { GetStaticPropsContext, NextPage } from 'next';

import { Locale } from '@silvertours/back-domain-sitegroup';
import { ErrorPageRequestHandler } from '@silvertours/back-runtime-landingpages';
import {
  ContentLayout,
  ErrorPageContent,
} from '@silvertours/common-landingpages-view';
import { LandingPageLegacy } from '@silvertours/front-pages';

const getNewRelic = () =>
  // @ts-ignore
  typeof window === 'undefined' ? require('newrelic') : window.newrelic;

const Error404: NextPage<ContentLayout<ErrorPageContent>> = page => {
  const newrelic = getNewRelic();

  if (newrelic) {
    newrelic.noticeError('The requested page was not found');
  }

  return <LandingPageLegacy.ErrorPage {...page} />;
};

export const getStaticProps = (context: GetStaticPropsContext) => {
  const handler = new ErrorPageRequestHandler(404, Locale.French.value);

  return handler.getStaticProps(context);
};

export default Error404;
